import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';

import { TranslateModule } from '@ngx-translate/core';

import { LoginFormComponent } from './login-form/login-form.component';

@Component({
  selector: 'app-login-client',
  templateUrl: './login-client.component.html',
  styleUrls: ['./login-client.component.scss'],
  standalone: true,
  imports: [LoginFormComponent, RouterLink, TranslateModule]
})
/**
 * Login component
 */
export class LoginClientComponent {}
