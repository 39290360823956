<div class="login-container gap-ml padding-lg shadow-1 container-border-radius padding-lg background-white">
  <a href="/operator/login" class="text-btn horizontal-end text-button" data-cy="operator-button">
    {{ 'LoginComponent.Client.IsOperator' | translate }}
  </a>

  <!-- Logo -->
  <div class="center row padding-sm">
    <img src="/assets/images/logo-simple-dark.svg" alt="Movenbus Logo" />
  </div>

  <h1 class="horizontal-center">{{ 'LoginComponent.Client.Title' | translate }}</h1>
  <app-login-form></app-login-form>
  <div class="column">
    <hr class="divider margin-top register" />
    <span class="horizontal-center"> {{ 'LoginComponent.NoAccount' | translate }}</span>
    <a class="text-btn text-btn--sm horizontal-center" routerLink="/signup" data-cy="signup-button">
      {{ 'LoginComponent.RegisterClient' | translate }}
    </a>
  </div>
</div>
